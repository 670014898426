import AnalyticsService from '@/@crema/services/AnalyticsService';
import { logd } from '@/@crema/utility/Logging';
import { getOriginalTitle, getPageSlug, isValidArray } from '@/@crema/utility/utils';
import { Box } from '@material-ui/core';
import { useCallback, useEffect, useRef } from 'react';
import CremaPage from '../CremaPage';
import Carousel from './Carousel';
import PageContent from './PageContent';
import Sections from './Sections';

export default function DynamicPage({ title, data, children }) {
  const ref = useRef();
  const slug = getPageSlug(data);
  const slug_name = data?.name || '';
  const id = 'dynamic_page';
  const name = `${id}_${slug}`;

  const tracking = useCallback(() => {
    logd('DynamicPage.useEffect', slug && data);
    if (slug && data) {
      const page_type = `${slug}_page`;

      const games = [];
      const promotions = [];

      // top carousel
      if (isValidArray(data?.slider)) {
        data?.slider.forEach((item, index) => {
          if (item?.value?.code) {
            const item_name =
              getOriginalTitle(item?.value?.title) || getOriginalTitle(item?.value?.description);
            promotions.push({
              item_id: item?.value?.code || '',
              item_name,
              index,
              item_list_id: `${slug}_carousel`,
              item_list_name: `${slug_name} Carousel`,
              location_id: `${slug}_top`
            });
          }
        });
      }

      if (isValidArray(data?.items)) {
        data?.items.forEach(({ value }) => {
          if (value?.code && isValidArray(value?.collections)) {
            value?.collections.forEach((col_item, index) => {
              if (col_item._link === 'banner') {
                promotions.push({
                  item_id: col_item?.key || '',
                  item_name: col_item?.slider?.[0]?.value?.title || '',
                  index,
                  item_list_id: value?.code || '',
                  item_list_name: value?.title || '',
                  location_id: `${slug}_${value?.code}`
                });
              }

              if (col_item._link === 'game_vendor') {
                promotions.push({
                  item_id: col_item?.code || '',
                  item_name: col_item?.name || '',
                  index,
                  item_list_id: value?.code || '',
                  item_list_name: value?.title || '',
                  location_id: `${slug}_${value?.code}`
                });
              }

              if (col_item._link === 'promotion') {
                promotions.push({
                  item_id: col_item?.name_slug || '',
                  item_name: col_item?.name || '',
                  index,
                  item_list_id: value?.code || '',
                  item_list_name: value?.title || '',
                  location_id: `${slug}_${value?.code}`
                });
              }

              if (col_item._link === 'game') {
                games.push({
                  item_id: col_item?.code || col_item?.name_slug || '',
                  item_name: col_item?.name || '',
                  index,
                  item_brand: col_item?.provider_name || '',
                  item_list_id: value?.code || '',
                  item_list_name: value?.title || '',
                  location_id: `${slug}_${value?.code}`
                });
              }
            });
          }
        });
      }

      logd('DynamicPage.promotions', promotions);
      logd('DynamicPage.games', games);

      if (promotions.length > 0) {
        AnalyticsService.view_promotion(page_type, promotions);
      }

      if (games.length > 0) {
        AnalyticsService.view_item_list(games);
      }
    }
  }, [data, slug, slug_name]);

  useEffect(() => {
    ref.current = setTimeout(tracking, 2000);
    return () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    };
  }, [tracking]);

  return (
    <CremaPage title={title} data={data?.head_meta}>
      <Box id={id} name={name} data-slug={slug}>
        <Carousel slug={slug} name={slug_name} data={data?.slider} />
        <Sections slug={slug} name={slug_name} data={data?.items} />
        {children}
        <PageContent slug={slug} name={slug_name} data={data?.page_content} />
      </Box>
    </CremaPage>
  );
}
