import Description from '@/@crema/core/Description';
import { Container } from '@material-ui/core';

export default function PageContent({ slug, data }) {
  if (!data) {
    return null;
  }

  return (
    <Container id={`${slug}-page-content`}>
      <Description description={data} />
    </Container>
  );
}
