import { DynamicPage } from '@/@crema';
import { usePageData } from '@/redux/reducers/Page';
import { CMS_PAGES } from '@/shared/constants/CMSConst';
import { useIntl } from 'react-intl';

const Home = () => {
  const { formatMessage } = useIntl();
  const slug = CMS_PAGES.Home;
  const data = usePageData(slug);

  const title = formatMessage({ id: 'sidebar.app.home' });

  return <DynamicPage title={'title'} data={data} />;
};

export default Home;
