import { Icon } from '@/@crema';
import FormSkeleton from '@/@crema/core/Skeleton/FormSkeleton';
import ReactLazyLoad from '@/shared/components/ReactLazyLoad';
import { LOCALES } from '@/shared/constants/AppEnums';
import { COLORS } from '@/shared/constants/ColorSets';
import { Box, Button, fade, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  container: {
    color: ({ contrast }) =>
      contrast ? fade(theme.palette.text.contrast, 0.8) : fade(theme.palette.text.primary, 0.7),
    position: 'relative',
    overflow: 'hidden'
  },
  readMore: {
    marginTop: 12,
    color: ({ contrast }) =>
      contrast ? fade(theme.palette.text.contrast, 0.8) : fade(theme.palette.text.primary, 0.5),
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
    '& .MuiIcon-root': {
      marginLeft: 6,
      fontSize: 12,
      '&::before': {
        color: 'unset'
      }
    }
  },
  readMoreContained: {
    '& .MuiIcon-root': {
      marginLeft: theme.spacing(2)
    }
  },
  arrowUp: {
    transform: 'rotateX(170deg)',
    transition: '0.5s ease'
  },
  arrowDown: {
    transform: 'rotateX(0)',
    transition: '0.5s ease'
  },
  descFi: {
    '& > div': {
      '& .desc-left': {
        '& a': {
          color: ({ isLandingPage }) =>
            isLandingPage ? theme.palette.text.primary : theme.palette.primary.main,
          textDecoration: ({ isLandingPage }) => (isLandingPage ? 'underline' : 'unset')
        }
      },
      '& .desc-right': {
        '& a': {
          color: ({ isLandingPage }) =>
            isLandingPage ? theme.palette.text.primary : theme.palette.primary.main,
          textDecoration: ({ isLandingPage }) => (isLandingPage ? 'underline' : 'unset')
        }
      }
    }
  },
  contentDescription: {
    display: 'inline',
    color: ({ locale }) => (locale === LOCALES.FI ? theme.palette.text.primary : undefined),
    '& a': {
      color: ({ isLandingPage }) => (isLandingPage ? COLORS.link : theme.palette.primary.main),
      textDecoration: ({ isLandingPage }) => (isLandingPage ? 'underline' : 'unset')
    },
    '& > div': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: ({ isLandingPage }) => (isLandingPage ? 'Montserrat' : undefined),
      '& .desc-left': {
        width: '49%',
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        }
      },
      '& .desc-right': {
        width: '49%',
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        }
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    }
  }
}));

const Description = ({
  description = '',
  contrast = false,
  isLandingPage = false,
  isLaunchGamePage = false
}) => {
  const router = useRouter();
  const locale = router?.locale || LOCALES.EN;
  const classes = useStyles({ contrast, isLandingPage, locale });
  const { formatMessage } = useIntl();
  const [isReading, setIsReading] = useState(false);
  const [isShowReadMore, setShowReadMore] = useState(true);

  const onReadMore = () => {
    setIsReading(!isReading);
  };

  const getLengthDescription = (description) => {
    const getLength = description?.length;
    if (getLength <= 1400) {
      setShowReadMore(false);
    }
  };

  useEffect(() => {
    getLengthDescription(description);
  }, [description]);

  if (!description) {
    return <FormSkeleton />;
  }

  if (description === '') return null;

  if (isLaunchGamePage) {
    return (
      <Box pt={8} pb={8}>
        <Box className={classes.container}>
          <Box
            id="description"
            className={clsx(classes.contentDescription, 'regular')}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Box>
      </Box>
    );
  }

  const heightBlur = isLandingPage ? 250 : 350;

  const renderButton = () => {
    if (isLandingPage) {
      return (
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button
            className={classes.readMoreContained}
            color="primary"
            variant="contained"
            onClick={onReadMore}>
            <Typography className="montserrat800" variant="body2">
              {isReading
                ? formatMessage({ id: 'common.seeLess' })
                : formatMessage({ id: 'common.readMore' })}
            </Typography>
            <Icon
              className={isReading ? classes.arrowUp : classes.arrowDown}
              name="keyboard-arrow-down"
              fontSize="small"
            />
          </Button>
        </Box>
      );
    }
    return (
      <Box
        className={classes.readMore}
        color="secondary"
        variant="link"
        size="small"
        onClick={onReadMore}>
        <Typography className="light" variant="body2">
          {isReading
            ? formatMessage({ id: 'common.seeLess' })
            : formatMessage({ id: 'common.readMore' })}
        </Typography>
        <Icon
          className={isReading ? classes.arrowUp : classes.arrowDown}
          name="keyboard-arrow-down"
          fontSize="small"
        />
      </Box>
    );
  };

  return (
    <ReactLazyLoad height={350}>
      <Box pt={8} pb={8}>
        <Box
          className={classes.container}
          height={isReading || !isShowReadMore ? '100%' : heightBlur}>
          <Box
            id="description"
            className={clsx(
              locale === LOCALES.FI ? classes.descFi : undefined,
              classes.contentDescription,
              'regular'
            )}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Box>
        {isShowReadMore ? renderButton() : null}
      </Box>
    </ReactLazyLoad>
  );
};

export default Description;
