import { logd } from '@/@crema/utility/Logging';
import { getCookie } from '@/@crema/utility/Session';
import Home from '@/modules/Home';
import { onGetPage } from '@/redux/actions/Page';
import { CMS_PAGES } from '@/shared/constants/CMSConst';
import { COOKIES } from '@/shared/constants/CookieConst';

/**
 *
 * @param {import('next').NextPageContext} ctx
 */
Home.getInitialProps = async (ctx) => {
  const isLoggedIn = !!getCookie(COOKIES.jwt_auth, ctx?.req?.headers);
  logd('Home.getInitialProps.isLoggedIn', isLoggedIn);

  const slug = CMS_PAGES.Home;
  const isSingleTon = false;

  await ctx.store.dispatch(onGetPage(slug, {}, false, isSingleTon));

  return {
    isLoggedIn,
    slug
  };
};

export default Home;
